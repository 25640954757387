import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as SearchStore from '../store/Search';
import ReCAPTCHA from "react-google-recaptcha";
import VehicleView from './VehicleView';
import { closeMobileNav } from '../store/common';
import { getCopy } from '../store/copyservice';

// At runtime, Redux will merge together...
type HomeProps = SearchStore.SearchState &
    typeof SearchStore.actionCreators &
    RouteComponentProps<{ code: string }>;

interface HomeState {
    search: string,
    captcha: string,
    copy: any
}

class Home extends React.PureComponent<HomeProps, HomeState> {

    private myRef = React.createRef<HTMLDivElement>();

    state: HomeState = {
        search: "",
        captcha: "",
        copy: null
    }

    componentWillMount() {
        closeMobileNav();
        console.log("code", this.props.match.params.code, new URLSearchParams(this.props.location.search).get("k"));
        if (this.props.match.params.code) {
            setTimeout(() => {
                this.props.searchVins(this.state.search, this.state.captcha, this.props.match.params.code);
            }, 500);
        }

        getCopy('Home').then(c => {
            this.setState((prevState) => {
                return { copy: c };
            });
        });
    }

    public onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
        const newValue = e.target.value;
        this.setState((prevState) => {
            return { search: newValue };
        });
    }

    public onCapChange(value: any) {
        console.log("Captcha value:", value);
        this.setState((prevState) => {
            return { captcha: value };
        });
    }

    public reset() {
        this.setState((prevState) => {
            return {
                search: "",
                captcha: ""
            };
        });
        this.props.reset();
    }

    componentWillReceiveProps = (nextProps: HomeProps) => {
        console.log(nextProps, this.props);

        if (nextProps.vehicle != null && nextProps.vehicle != undefined && this.props.vehicle == undefined) {
            setTimeout(() => window.scrollTo({
                top: this.myRef!.current!.offsetTop - 35,
                behavior: 'smooth',
            }));
        }
    }

    public render() {
        return (
        <React.Fragment>
        {/* ======= Hero Section ======= */}
        <section id="hero" className="d-flex align-items-center">
        </section>{/* End Hero */}

        <main id="main">

                {/* ======= Why Us Section ======= */}
                <section id="why-us" className="why-us">
                    <div className="container appointment" id="appointment">
                        <div className="row">
                            <div className="col-lg-4 offset-lg-4 d-flex align-items-stretch">
                                <div className="content">
                                    <form className="php-email-form">
                                            <h3>{this.state.copy?.Home_Heading}</h3>
                                        <p>
                                                {this.state.copy?.Home_Instructions}
                                        </p>
                                        <div className="row">
                                            <div className="col-md-12 form-group">
                                                    <input type="text" name="name" className="form-control" id="name" placeholder={this.state.copy?.Home_VIN_Placeholder}
                                                        data-rule="minlen:4" data-msg={this.state.copy?.Home_VIN_LengthRule} value={this.state.search} onChange={(e) => this.onSearchChange(e)}
                                                />
                                            </div>
                                        </div>
                                        {this.props.vehicle === undefined &&
                                            <div className="row">
                                                <div className="row">
                                                    <ReCAPTCHA
                                                        sitekey="6LcOyUMhAAAAAKhVvP3bZ1hKmumd7v2gxr1kDTgo"
                                                        onChange={(val) => this.onCapChange(val)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className="mb-3">
                                            {this.props.isLoading &&
                                                    <div className="loading">{this.state.copy?.Common_Loading}</div>
                                            }
                                            {/*
                                            <div className="error-message" />
                                            <div className="sent-message">Your appointment request has been sent successfully. Thank you!</div>
                                            */}
                                        </div>
                                        {(this.props.vehicle === undefined && !this.props.isLoading) &&
                                                <div className="text-center"><button type="button" onClick={() => { this.props.searchVins(this.state.search, this.state.captcha, null); }}>{this.state.copy?.Home_Search_Button}</button></div>
                                        }
                                        {this.props.vehicle === null &&
                                            <div className="mb-3">
                                                    <b>{this.state.copy?.Home_Result}</b> {this.state.copy?.Home_Result_Not_Registered}
                                                <div className="text-center mt-3">
                                                        <button type="button" onClick={() => { this.reset(); }}>{this.state.copy?.Home_Search_Button_Again}</button>
                                                </div>
                                            </div>
                                        }
                                        {this.props.vehicle &&
                                            <div className="mb-3">
                                                    <b>{this.state.copy?.Home_Result}</b> {this.state.copy?.Home_Result_Registered} <i className="bi bi-check-circle-fill"></i>
                                                <div className="text-center mt-3">
                                                        <button type="button" onClick={() => { this.reset(); }}>{this.state.copy?.Home_Search_Button_Again}</button>
                                                </div>
                                            </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>{/* End Why Us Section */}

                <div ref={this.myRef}>
                {this.props.vehicle &&
                    <VehicleView></VehicleView>
                }
                </div>

        </main>
        </React.Fragment>
        );
    }

   
}

export default connect(
    (state: ApplicationState) => state.search, // Selects which state properties are merged into the component's props,
    SearchStore.actionCreators
)(Home as any);
