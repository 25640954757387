// import 'bootstrap/dist/css/bootstrap.css';
import './style.css'

import React, { useEffect, useState } from 'react';
import CookieBot from 'react-cookiebot'
import {
    NavItem, NavLink,
    Dropdown,DropdownToggle,DropdownMenu,DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getCopy, getLangs, setLang } from '../store/copyservice';

const domainGroupId = 'fdd5c1e9-f7da-4763-ac4b-9c622e834ef7';

export default (props: { children?: React.ReactNode }) => {
    const [copy, setCopy] = useState<any | null>(null);
    const [langs, setLangs] = useState<any | null>([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    useEffect(() => {
        getCopy('Layout').then(c => {
            setCopy(c);
        });
        getLangs().then(ls => {
            setLangs(ls);
            console.log(ls);
        });
    }, []);

    const handleLangChange = (e: React.FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.name;
        console.log(newValue);
        setLang(newValue);
    }

    return (
        <React.Fragment>
            <div id="cookie-declaration-data2" style={{ "display": "none" }} >
                <CookieBot domainGroupId={domainGroupId} />
            </div>
            {/* ======= Header ======= */}
            <header id="header" className="fixed-top">
                <div className="container d-flex align-items-center">
                    {/*<h1 className="logo me-auto"><a href="index.html">Medilab</a></h1>*/}

                    {/* Uncomment below if you prefer to use an image logo */}
                    <a href="/" className="logo me-auto"><img src="assets/img/datadot-logo.png" alt="" className="img-fluid" /></a>
                    <nav id="navbar" className="navbar order-last order-lg-0">
                        <ul>
                            <NavItem>
                                <NavLink tag={Link} to="/">
                                    {copy?.Layout_Home}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/register">
                                    {copy?.Layout_Register}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/contact">
                                    {copy?.Layout_Contact}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle caret>Language</DropdownToggle>
                                    <DropdownMenu>
                                        {langs.map((lang: any) =>
                                            <DropdownItem key={lang.item1} name={lang.name} onClick={handleLangChange}>{lang.displayName}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </Dropdown>
                            </NavItem>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle" />
                    </nav>{/* .navbar */}
                </div>
            </header>{/* End Header */}
            {props.children}
            {/* Hello world */}
            <footer id="footer">
                <div className="container d-md-flex py-4">
                    <div className="me-md-auto text-center text-md-start">
                        <div className="copyright">
                            &copy; 2022 <strong>DataDot Technology Ltd</strong>
                        </div>
                    </div>
                    <div className="content-links text-center text-md-right pt-3 pt-md-0">
                        <Link to="/content/privacy">
                            {copy?.Layout_Privacy}
                        </Link>
                        <Link to="/content/cookie-policy">
                            {copy?.Layout_Cookie_Policy}
                        </Link>
                        <Link to="/content/terms-and-conditions">
                            {copy?.Layout_TermsandConditions}
                        </Link>
                    </div>
                </div>
            </footer>{/* End Footer */}

        </React.Fragment>
    )
};
