import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Home, File, Layers, ShoppingCart, Users, BarChart2, FileText } from 'react-feather';
import './SideMenu.css';

export default class SideMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                <div className="position-sticky pt-3">
                    <ul className="nav flex-column">
                        <NavItem>
                            <NavLink tag={Link} to="/manager">
                                <Home />
                                Dashboard
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/manager/upload-idents">
                                <File />
                                Upload IDs
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/manager/idents">
                                <Layers />
                                Manage IDs
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/manager/upload-vins">
                                <File />
                                Upload VINs
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/manager/vins">
                                <ShoppingCart />
                                Manage VINs
                            </NavLink>
                        </NavItem>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                <Users />
                                Api Keys
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                <BarChart2 />
                                Reports
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                <Layers />
                                Meta-Data
                            </a>
                        </li>
                    </ul>


                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span>Metadata</span>
                    </h6>
                    <ul className="nav flex-column mb-2">
                        <NavItem>
                            <NavLink tag={Link} to="/manager/comps/Country_Of_Origin">
                                <FileText />
                                Country of Origin
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/manager/comps/Manufacturer">
                                <FileText />
                                Manufacturer
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/manager/comps/Vehicle_Type">
                                <FileText />
                                Vehicle Type
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/manager/comps/Vehicle_Descriptor">
                                <FileText />
                                Vehicle Descriptor
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/manager/comps/Model_Year">
                                <FileText />
                                Model Year
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/manager/comps/Manufacturing_Plant">
                                <FileText />
                                Manufacturing Plant
                            </NavLink>
                        </NavItem>
                    </ul>

                </div>
            </nav>
        );
    }

}
