

export function getCopy(setid: string) {
    return fetch(`/api/copy?setid=${setid}`, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            if (!response.ok) {
                return (response.json() as Promise<any>);
            }
            return response.json() as Promise<any>;
        });
}

export function getLangs() {
    return fetch(`/api/language`, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            if (!response.ok) {
                return (response.json() as Promise<any>);
            }
            return response.json() as Promise<any>;
        });
}

export function setLang(lang:string) {
    return fetch(`/api/language`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({name:lang}),
    })
        .then(response => {
            if (!response.ok) {
                console.log("There was a problem with setLang")
            }
            window.location.reload();
        });
}

export function testFunc() {
    return "test";
}