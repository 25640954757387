import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SearchState {
    isLoading: boolean;
    search: string | undefined;
    vehicle: Vehicle | undefined | null;
}

export interface SearchResult {
    Success: boolean;
    Vehicle?: Vehicle;
}

export interface Vehicle {
    make?: string;
    model?: string;
    type?: string;
    year?: string;
    colour?: string;
    country?: string;
    vin?: string;
    ident?: string;
    countryOfOriginCode: string;
    imageUrl?: string;
    dateOfRegistration?: string;
    isVIN?: boolean;
}

interface SearchVinsAction {
    type: 'SEARCH_VINS';
    search?: string;
    catchya?: string;
}

interface ReceiveVinAction {
    type: 'RECEIVE_VIN';
    vehicle: Vehicle | undefined | null;
}

type KnownAction = SearchVinsAction | ReceiveVinAction;


export const actionCreators = {
    searchVins: (search: string | undefined, catchya: string | undefined, qrKey: string | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if ((search == '' || search === undefined || catchya == '' || catchya === undefined) && (qrKey == null))
            return;
        const appState = getState();
        // console.log("requestVins", search, appState.vins.search, pageIndex, appState.vins.pageIndex);
        if (appState && appState.search) {
            console.log("fetch", search);
            fetch(`/api/search`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ Search: search, Captya:catchya, QRKey:qrKey }),
            })
                .then(response => {
                    if (!response.ok) {
                        return (response.json() as Promise<any>);
                    }
                    return response.json() as Promise<SearchResult>;
                })
                .then(data => {
                    if (data.success) {
                        dispatch({ type: 'RECEIVE_VIN', vehicle: data.vehicle as Vehicle });
                    }
                    else {
                        dispatch({ type: 'RECEIVE_VIN', vehicle: null });
                    }
                })
                .catch(error => {
                    console.error('There has been a problem with your fetch operation:', error);
                });

            dispatch({ type: 'SEARCH_VINS', search: search });
        }
    },
    reset: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'RECEIVE_VIN', vehicle: undefined });
    }
};


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SearchState = { vehicle: undefined, isLoading: false, search: undefined };

export const reducer: Reducer<SearchState> = (state: SearchState | undefined, incomingAction: Action): SearchState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SEARCH_VINS':
            return {
                vehicle: state.vehicle,
                search: state.search,
                isLoading: true
            };
        case 'RECEIVE_VIN':
            return {
                search: state.search,
                vehicle: action.vehicle,
                isLoading: false
            };
    }

    return state;
};
