import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as RegisterStore from '../store/Register';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { closeMobileNav } from '../store/common';
import { getCopy } from '../store/copyservice';

// At runtime, Redux will merge together...
type RegisterProps = RegisterStore.RegisterState &
    typeof RegisterStore.actionCreators &
    RouteComponentProps<{}>;

interface FormValidation {
    formSubmitAttempted: boolean,
    formIsValid: boolean,
    errors: Map<string,string>
}

interface RegisterViewState {
    register: RegisterStore.RegisterModel,
    identParts: Array<string>,
    vinConfirmation: string,
    validation: FormValidation,
    copy: any
}

const options = countryList().getData();

class Register extends React.PureComponent<RegisterProps, RegisterViewState> {

    state: RegisterViewState = {
        register: { vinCode: '', identCode: '', countryCode: '', email: '', makeId: '', modelId: '', bodyTypeId: '', year: '', primaryColourId:''},
        identParts: ["","",""],
        validation: { formIsValid: false, formSubmitAttempted: false, errors: new Map<string, string>() },
        vinConfirmation: '',
        copy: null
    }

    componentWillMount() {
        closeMobileNav();
        this.props.getFormOptions();
        getCopy('Register').then(c => {
            this.setState((prevState) => {
                return { copy: c };
            });
        });
    }

    runValidation = (state: RegisterViewState, register: RegisterStore.RegisterModel, vinConfirmation: string): FormValidation => {
        let validation: FormValidation = { formIsValid: state.validation.formIsValid, formSubmitAttempted: state.validation.formSubmitAttempted, errors: new Map<string, string>() }
        if (register.vinCode == "") {
            validation.errors.set("vinCode", this.state.copy?.Register_Validation_VinCode);
        }
        if (vinConfirmation == "") {
            validation.errors.set("vinCodeConfirm", this.state.copy?.Register_Validation_VinCodeConfirm);
        }
        else {
            if (register.vinCode !== vinConfirmation) {
                validation.errors.set("vinCodeConfirm", this.state.copy?.Register_Validation_VinCodeNoMatch);
            }
        }
        if (register.identCode == "") {
            validation.errors.set("identCode", this.state.copy?.Register_Validation_IdentCode);
        }
        if (register.countryCode == "") {
            validation.errors.set("countryCode", this.state.copy?.Register_Validation_CountryCode);
        }
        if (register.email == "") {
            validation.errors.set("email", this.state.copy?.Register_Validation_Email);
        }
        if (register.makeId == "") {
            validation.errors.set("makeId", this.state.copy?.Register_Validation_MakeId);
        }
        /*
        if (register.modelId == "") {
            validation.errors.set("modelId", "You must provide an Model");
        }
        if (register.bodyTypeId == "") {
            validation.errors.set("type", "You must provide an Type");
        }
        if (register.year == "") {
            validation.errors.set("year", "You must provide an Year");
        }
        if (register.primaryColourId == "") {
            validation.errors.set("colour", "You must provide an Colour");
        }
        */
        /*
        if (this.props.registerResult !== undefined && this.props.registerResult["identCode"] != undefined) {
            validation.errors.set("identCode", this.props.registerResult["identCode"][0]);
        }
        */

        validation.formIsValid = validation.errors.size === 0;
        return validation;
    }

    onChange = (id:string,value:string | null) => {
        // const newValue = e.target.value;
        // const id = e.target.id;
        // console.log("e.target",e.target);
        var newValue = value || "";

        this.setState((prevState) => {
            let vinConfirmation: string = id == "vinCodeConfirm" ? newValue : prevState.vinConfirmation;
            let identParts = [...prevState.identParts];

            let register: RegisterStore.RegisterModel = {
                vinCode: id == "vinCode" ? newValue : prevState.register.vinCode,
                identCode: id == "identCode" ? newValue : prevState.register.identCode,
                countryCode: id == "countryCode" ? newValue : prevState.register.countryCode,
                email: id == "email" ? newValue : prevState.register.email,
                makeId: id == "makeId" ? newValue : prevState.register.makeId,
                modelId: id == "modelId" ? newValue : prevState.register.modelId,
                bodyTypeId: id == "bodyTypeId" ? newValue : prevState.register.bodyTypeId,
                year: id == "year" ? newValue : prevState.register.year,
                primaryColourId: id == "primaryColourId" ? newValue : prevState.register.primaryColourId,
                model: id == "model" ? newValue : prevState.register.model,
            }

            /*
            if (id.indexOf("identCode") === 0) {
                identParts[parseInt(id.replace("identCode", ""))] = value || '';
                register.identCode = identParts.join('-');
            }
            */

            let validation = this.runValidation(prevState, register, vinConfirmation);

            return { register, validation, vinConfirmation, identParts };
        });
    }

    onChangeSelect = (event: any, id: string) => {
        const value = event.value;
        console.log(value);
        this.onChange(id, value);
        //setSelectedOption(value);
         //"countryCode", e === null || e === undefined ? '' : e.value as CountryData)
    };

    submitForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log("test");
        let validation = this.runValidation(this.state, this.state.register, this.state.vinConfirmation);
        
        this.setState((prevState) => {
            validation.formSubmitAttempted = true;
            return {validation}
        });

        if (validation.formIsValid) {
            console.log("would've fired");
            this.props.registerVin(this.state.register, undefined);
        }
        
    }

    public render() {

        var validation = (field: string) => {
            if (!this.state.validation.formSubmitAttempted)
                return null;
            var err = this.state.validation.errors.get(field);
            if(err != undefined)
                return (<div className="validate">{err}</div>)
            if (this.props.registerResult !== undefined && this.props.registerResult.modelState != undefined && this.props.registerResult.modelState[field as keyof RegisterStore.ModelState] != undefined) {
                return (<div className="validate">{this.props.registerResult.modelState[field as keyof RegisterStore.ModelState]}</div>)
            }
            return null
        }

        const h = '40px';
        const customStyles = {
            control: (provided: any, state: { isFocused: any; }) => ({
                ...provided,
                background: '#fff',
                borderColor: '#9e9e9e',
                minHeight: h,
                height: h,
                boxShadow: state.isFocused ? null : null,
            }),

            valueContainer: (provided: any, state: any) => ({
                ...provided,
                height: h,
                padding: '0 6px'
            }),

            input: (provided: any, state: any) => ({
                ...provided,
                margin: '-15px 0px',
            }),
            indicatorSeparator: (state: any) => ({
                display: 'none',
            }),
            indicatorsContainer: (provided: any, state: any) => ({
                ...provided,
                height: h,
            }),
            option: (provided: any, state: any) => ({
                ...provided,
                borderBottom: '1px dotted pink',
                color: state.isSelected ? 'red' : 'black',
                padding: 5,
            })
        }

        const vehicleModels = this.props.options ? this.props.options!.models.filter(x => x.parentId === this.state.register.makeId) : [];

        // console.log(this.props.registerResult);
        if (this.props.registerResult !== undefined && this.props.registerResult.success) {
            return (
                <React.Fragment>
                    {/* ======= Hero Section ======= */}
                    <section id="hero" className="d-flex align-items-center">
                        <div className="container">
                        </div>
                    </section>{/* End Hero */}

                    <main id="main">
                    {/* ======= Why Us Section ======= */}
                    <section id="why-us" className="why-us">
                        <div className="container appointment" id="appointment">
                            <div className="row">
                                <div className="col-lg-4 offset-lg-4 d-flex align-items-stretch">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="content">
                                                <form className="php-email-form">
                                                        <h3>{this.state.copy?.Register_Complete_Title}</h3>
                                                        <i className="bi bi-check-circle-fill"></i> {this.state.copy?.Register_Complete_Message}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>{/* End Why Us Section */}
                    </main>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                {/* ======= Hero Section ======= */}
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                    </div>
                </section>{/* End Hero */}

                <main id="main">
                {/* ======= REgister Section ======= */}
                <section id="why-us" className="why-us">
                    <div className="container appointment" id="appointment">
                        <div className="row">
                            <div className="col-lg-4 offset-lg-4 d-flex align-items-stretch">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="content">
                                            <form className="php-email-form">
                                                <h3>{this.state.copy?.Register_Heading}</h3>
                                                <div className="row identcodes">
                                                    <label htmlFor="ident">{this.state.copy?.Register_Instructions}</label>
                                                    <div className="col-12 form-group">
                                                        <input type="text" name="identCode" id="identCode" className="form-control" onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                                    </div>
                                                    <div className="col-md-12 form-group ident-valid">
                                                        {validation("identCode")}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label htmlFor="makeId">{this.state.copy?.Register_Label_Make}</label>
                                                    <div className="col-md-12 form-group">
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            styles={customStyles}
                                                            onChange={(e) => this.onChangeSelect(e,"makeId")}
                                                            options={this.props.options!.makes}
                                                        />
                                                        {validation("makeId")}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label htmlFor="model">{this.state.copy?.Register_Label_Model}</label>
                                                    <div className="col-md-12 form-group">
                                                        <input type="text" name="model" id="model" className="form-control" onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label htmlFor="bodyTypeId">{this.state.copy?.Register_Label_VehicleType}</label>
                                                    <div className="col-md-12 form-group">
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            styles={customStyles}
                                                            onChange={(e) => this.onChangeSelect(e, "bodyTypeId")}
                                                            options={this.props.options!.bodyTypes}
                                                        />
                                                        {validation("bodyTypeId")}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label htmlFor="year">{this.state.copy?.Register_Label_YearofManufacture}</label>
                                                    <div className="col-md-12 form-group">
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            styles={customStyles}
                                                            onChange={(e) => this.onChangeSelect(e, "year")}
                                                            options={this.props.options!.yearOfManufactures}
                                                        />
                                                        {validation("year")}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label htmlFor="primaryColourId">{this.state.copy?.Register_Label_VehiclePrimaryColour}</label>
                                                    <div className="col-md-12 form-group">
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            styles={customStyles}
                                                            onChange={(e) => this.onChangeSelect(e, "primaryColourId")}
                                                            options={this.props.options!.primaryColours}
                                                        />
                                                        {validation("primaryColourId")}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label htmlFor="vinCode">{this.state.copy?.Register_Label_EnterVIN}</label>
                                                    <div className="col-md-12 form-group">
                                                        <input type="text" name="vinCode" id="vinCode" className="form-control" placeholder="VIN" onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                                        {validation("vinCode")}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label htmlFor="vinCodeConfirm">{this.state.copy?.Register_Label_ReEnterVIN}</label>
                                                    <div className="col-md-12 form-group">
                                                        <input type="text" name="vinCodeConfirm"  id="vinCodeConfirm" className="form-control" placeholder="VIN confirm" onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                                        {validation("vinCodeConfirm")}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label htmlFor="countryCode">{this.state.copy?.Register_Label_Country}</label>
                                                    <div className="col-md-12 form-group">
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            styles={customStyles}
                                                            onChange={(e) => this.onChangeSelect(e,"countryCode")}
                                                            options={options}
                                                        />
                                                        {validation("countryCode")}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label htmlFor="email">{this.state.copy?.Register_Label_Email}</label>
                                                    <div className="col-md-12 form-group">
                                                            <input type="text" name="email" id="email" className="form-control" placeholder={this.state.copy?.Register_Placeholder_Email} onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                                        {validation("email")}
                                                    </div>
                                                </div>
                                                {/*this.props.vehicle === undefined &&
                                                    <div className="row">
                                                        <div className="row">
                                                            <ReCAPTCHA
                                                                sitekey="6LcOyUMhAAAAAKhVvP3bZ1hKmumd7v2gxr1kDTgo"
                                                                onChange={(val) => this.onCapChange(val)}
                                                            />
                                                        </div>
                                                    </div>
                                                */}
                                                <div className="mb-3">
                                                    {this.props.isLoading &&
                                                        <div className="loading">{this.state.copy?.Common_Loading}</div>
                                                    }
                                                    <div className="sent-message"></div>
                                                </div>
                                                    <div className="text-center"><button type="button" onClick={(e) => { this.submitForm(e) }}>{this.state.copy?.Register_Button_Register}</button></div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>{/* End Why Us Section */}
            </main>
            </React.Fragment>
        );
    }

   
}

export default connect(
    (state: ApplicationState) => state.register, // Selects which state properties are merged into the component's props
    RegisterStore.actionCreators
)(Register as any);
