import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ContactState {
    isLoading: boolean;
    contact: ContactModel | undefined;
    contactResult: ContactResult | undefined;
}

export interface ContactResult {
    success: boolean;
    modelState: ModelState | undefined;
}

export interface ModelState {
    identCode: Array<string>;
}

export interface ContactModel {
    message?: string;
    phone?: string;
    email?: string;

    name?: string;
    badgeNumber?: string;
    department?: string;
    address?: string;
    VIN?: string;
    UID?: string;
}

interface ContactVinAction {
    type: 'SEND_CONTACT';
    contact?: ContactModel;
    catchya?: string;
}

interface ReceiveVinResultAction {
    type: 'RECEIVE_CONTACT_RESULT';
    result: ContactResult;
}

interface SetupContactAction {
    type: 'SETUP_CONTACT';
    contact?: ContactModel;
}

type KnownAction = ContactVinAction | ReceiveVinResultAction | SetupContactAction;


export const actionCreators = {
    setupContact: (contact: ContactModel | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log(contact);
        dispatch({ type: 'SETUP_CONTACT', contact: contact });
    },
    sendContact: (contact: ContactModel | undefined, catchya: string | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (catchya === undefined)
            return;
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        // console.log("requestVins", contact, appState.vins.contact, pageIndex, appState.vins.pageIndex);
        if (appState && appState.contact) {
            console.log("fetch", contact);
            fetch(`/api/${contact!.UID || contact!.VIN ? "policecontact" : "contact" }`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...contact, Captya: catchya }),
            })
                .then(response => {
                    /*
                    if (!response.ok) {
                        return (response.json() as Promise<ContactResult>);
                    }
                    */
                    return response.json() as Promise<ContactResult>;
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_CONTACT_RESULT', result: data });
                })
                .catch(error => {
                    console.error('There has been a problem with your fetch operation:', error);
                });

            dispatch({ type: 'SEND_CONTACT', contact: contact });
        }
    }
};


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ContactState = { contactResult: undefined, isLoading: false, contact: undefined };

export const reducer: Reducer<ContactState> = (state: ContactState | undefined, incomingAction: Action): ContactState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SEND_CONTACT':
            return {
                contactResult: state.contactResult,
                contact: state.contact,
                isLoading: true
            };
        case 'RECEIVE_CONTACT_RESULT':
            return {
                contact: state.contact,
                contactResult: action.result,
                isLoading: false
            };
        case 'SETUP_CONTACT':
            return {
                contact: action.contact,
                contactResult: state.contactResult,
                isLoading: false
            };
    }

    return state;
};