import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as VinsStore from '../store/Vins';
import './ManagerHome.css';

// At runtime, Redux will merge together...
type VinProps =
    VinsStore.VinsState // ... state we've requested from the Redux store
    & typeof VinsStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ pageIndex: string }>; // ... plus incoming routing parameters

interface VinsState {
    search: string,
}

class ManagerHome extends React.PureComponent<VinProps, VinsState> {

    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    public render() {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Dashboard</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="btn-group me-2">
                            <button type="button" className="btn btn-sm btn-outline-secondary">Share</button>
                            <button type="button" className="btn btn-sm btn-outline-secondary">Export</button>
                        </div>
                        <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                            <span data-feather="calendar"></span>
                            This week 
                        </button>
                    </div>
                </div>
                <canvas className="my-4 w-100" id="myChart" width="900" height="380"></canvas>
                {this.renderVinsTable()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        this.props.requestVins(0, "");
    }

    private renderVinsTable() {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>VIN</th>
                        <th>dDotDna Id</th>
                        <th>Date Added</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.vins.map((vin: VinsStore.Vin) =>
                        <tr key={vin.id}>
                            <td>{vin.code}</td>
                            <td>{vin.identDataDotID}</td>
                            <td>{vin.createDateStr}</td>
                            <td><a href={"/home/ddid/"+vin.code} target="_blank">check</a></td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.vins, // Selects which state properties are merged into the component's props
    VinsStore.actionCreators // Selects which action creators are merged into the component's props
)(ManagerHome as any);
