import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LoginState {
    isLoading: boolean;
    login: LoginModel | undefined;
    loginResult: LoginResult | undefined;
}

export interface LoginResult {
    success: boolean;
    redirect: string | undefined;
    imageUrl: string | undefined;
    modelState: ModelState | undefined;
}

export interface ModelState {
    identCode: Array<string>;
}

export interface LoginModel {
    email?: string;
    password?: string;
    code?: string;
}

interface LoginAction {
    type: 'SEND_LOGIN';
    login?: LoginModel;
    catchya?: string;
}

interface ReceiveLoginResultAction {
    type: 'RECEIVE_LOGIN_RESULT';
    result: LoginResult;
}

interface SetupLoginAction {
    type: 'SETUP_LOGIN';
    login?: LoginModel;
}

type KnownAction = LoginAction | ReceiveLoginResultAction | SetupLoginAction;


export const actionCreators = {
    setupLogin: (login: LoginModel | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log(login);
        dispatch({ type: 'SETUP_LOGIN', login: login });
    },
    sendLogin: (login: LoginModel | undefined, catchya: string | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (catchya === undefined)
            return;
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        // console.log("requests", login, appState.vins.login, pageIndex, appState.vins.pageIndex);
        if (appState && appState.login) {
            console.log("fetch", login);
            fetch(`/api/login`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...login, Captya: catchya }),
            })
                .then(response => {
                    return response.json() as Promise<LoginResult>;
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_LOGIN_RESULT', result: data });
                })
                .catch(error => {
                    console.error('There has been a problem with your fetch operation:', error);
                });

            dispatch({ type: 'SEND_LOGIN', login: login });
        }
    },
    sendCode: (login: LoginModel | undefined, redirect: string | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        if (appState && appState.login) {
            console.log("fetch", login);
            fetch(`/api/${redirect}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: login!.code
                }),
            })
                .then(response => {
                    return response.json() as Promise<LoginResult>;
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_LOGIN_RESULT', result: data });
                })
                .catch(error => {
                    console.error('There has been a problem with your fetch operation:', error);
                });

            dispatch({ type: 'SEND_LOGIN', login: login });
        }
    }
};


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: LoginState = { loginResult: undefined, isLoading: false, login: undefined };

export const reducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SEND_LOGIN':
            return {
                loginResult: state.loginResult,
                login: state.login,
                isLoading: true
            };
        case 'RECEIVE_LOGIN_RESULT':
            return {
                login: state.login,
                loginResult: action.result,
                isLoading: false
            };
        case 'SETUP_LOGIN':
            return {
                login: action.login,
                loginResult: state.loginResult,
                isLoading: false
            };
    }

    return state;
};