import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import LayoutManager from './components/LayoutManager';
import Home from './components/Home';
import Register from './components/Register';
import Contact from './components/Contact';
import ManagerHome from './components/ManagerHome';
import Counter from './components/Counter';
import FetchData from './components/FetchData';
import IdentUpload from './components/IdentUpload';
import Idents from './components/Idents';
import VinUpload from './components/VinUpload';
import Vins from './components/Vins';
import Comps from './components/Comps';
import CompItem from './components/CompItem';
import Content from './components/Content';
import Login from './components/Login';

export default () => (
    <React.Fragment>
        <Route exact path={["/", "/register", "/contact", "/content/:tag", "/qk/:code", "/login"]}>
            <Layout>
                <Route exact path='/' component={Home} />
                <Route exact path='/register' component={Register} />
                <Route exact path='/contact' component={Contact} />
                <Route exact path='/content/:tag' component={Content} />
                <Route exact path='/qk/:code' component={Home} />
                <Route exact path='/login' component={Login} />
            </Layout>
        </Route>
        <Route path="/manager/">
            <LayoutManager>
                <Route exact path='/manager' component={ManagerHome} />
                <Route exact path='/manager/upload-idents' component={IdentUpload} />
                <Route exact path='/manager/idents' component={Idents} />
                <Route exact path='/manager/upload-vins' component={VinUpload} />
                <Route exact path='/manager/vins' component={Vins} />
                <Route exact path='/manager/comps/:compType' component={Comps} />
                <Route exact path='/manager/compitem/:compType' component={CompItem} />
                <Route path='/counter' component={Counter} />
                <Route path='/fetch-data/:startDateIndex?' component={FetchData} />
            </LayoutManager>
        </Route>
    </React.Fragment>
);
