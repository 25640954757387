import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';

type ContentProps = RouteComponentProps<{ tag: string }>;

class Content extends React.PureComponent<ContentProps> {

    componentDidMount() {
        console.log("in-content", this.props.match.params.tag.toLowerCase())
        if (this.props.match.params.tag.toLowerCase() == "cookie-policy") {
            console.log("running")

            const fillFromExternalDivs = () => {
                let cookieData = document.getElementById("cookie-declaration-data")
                let data = null;
                console.log("nodezname", cookieData?.firstElementChild?.nodeName);
                if (cookieData?.firstElementChild?.nodeName == "DIV")
                    data = cookieData?.firstElementChild?.innerHTML;
                else {
                    let cookieData = document.getElementById("cookie-declaration-data2")
                    if (cookieData?.firstChild?.nodeName == "DIV")
                        data = cookieData?.firstElementChild?.innerHTML;
                }

                const cookieBox = document.getElementById("cookie-declaration-insert");
                console.log("elm", cookieData, cookieBox, data);

                cookieBox!.innerHTML = data || "";
                return cookieBox!.innerHTML != "";
            }

            var done = fillFromExternalDivs();
            if (!done) {
                let times = 1;
                const timer = setInterval(() => {
                    console.log("running fillFromExternalDivs", timer)
                    if (fillFromExternalDivs() || times > 5)
                        clearTimeout(timer);
                    else
                        times += 1;
                },1000);
            }

        }
    }

    public render() {

        var content = { title: "", body: (<div></div>) };

        switch (this.props.match.params.tag.toLowerCase()) {
            case "int-epw":
                content.title = "DataDotDNA TDIS - Complimentary Theft Excess Protection";
                content.body = (
                    <div>
                        <h2>DataDotDNA &copy; TDIS  Complimentary Theft Excess Protection - Terms and Conditions</h2>
                            <p>The DataDotDNA Theft Excess Protection is a complimentary program available to private purchasers of new vehicles that have been fitted with the DataDotDNA Theft Deterrent Identification System by the vehicle's manufacturer.</p>
                        <p>In the event the vehicle is stolen within three years of the vehicle's purchase date and is not recovered within 30 days of the theft event, DataDot Technology Ltd will refund an amount equal to the insurance excess deducted from a settled claim under a comprehensive vehicle insurance policy covering that vehicle up to a maximum amount of &#162;1000 (inclusive of any relevant taxes or charges) subject to the following conditions being met;</p>
                            <p>General Conditions</p>
                            <ol>
                                <li>The program is only applicable to a new vehicle that has been purchased for private use and has been fitted with the DataDotDNA Theft Deterrent Identification System by the vehicle's manufacturer.</li>
                                <li>The vehicle is covered by a comprehensive insurance policy with a licensed insurance company that includes cover for theft at the time of the theft event. </li>
                                <li>A refund provided under the program will be payable to the person or entity named as the insured on the comprehensive policy (the insured).</li>
                                <li>The vehicle is registered with the relevant country road/transport authority at the time of the theft event. </li>
                                <li>The theft event occurs within the first 3 years of the new vehicle purchase.</li>
                                <li>The theft event is reported to the relevant police authority.</li>
                                <li>The vehicle is not recovered within 30 days of the theft event.*</li>
                                <li>The insurer of the vehicle has accepted a total loss theft claim and has paid the insured the full proceeds of the claim under the comprehensive insurance policy.</li>
                            </ol>
                            <p />
                            <p>Documentation required to support a request for an excess refund.</p>
                            <p>To be eligible for a refund of the theft excess the insured must comply with the following:</p>
                            <ol>
                                <li>Notify DataDot Technology Ltd at <a href="mailto:excessprotection@datadotdna.com">excessprotection@datadotdna.com</a> within 30 days of the theft event. </li>
                                <li>Provide DataDot Technology Ltd a copy of the police report</li>
                                <li>Provide DataDot Technology Ltd a copy of the notification of claim settlement detailing the amount of the excess deducted from the claim.</li>
                            </ol>
                            <p>*Not Recovered:</p>
                            <p>For the purposes of the Theft Excess Protection program "not recovered" means the location of the vehicle is unknown to the insured, a relevant authority or the insured's insurance company.</p>
                            <p>A vehicle that is located but is still subject to a total loss theft claim due to its damaged condition, an inability to retrieve it or for any other reason is assumed to be recovered and is not eligible for the excess refund unless the vehicle is located after a total loss theft claim has been accepted and paid to the insured and the vehicle has become the property of the insurance company.</p>
                            <p>Disclaimer:</p>
                            <p>The DataDotDNA Theft Excess Protection program is a marketing program promoted by DataDot Technology Ltd and its subsidiary DataDot Technology (UK) Ltd. The program is provided by DataDot Technology Ltd.</p>
                            <p>The DataDotDNA Theft Deterrent Identification System is designed to deter theft of vehicles but cannot and does not guarantee that an individual vehicle will not be stolen. DataDot Technology Ltd and its subsidiary DataDot Technology (UK) Ltd. will not be liable for any loss or damages whatsoever arising from the theft of the vehicle in any circumstances.</p>
                            <p>The offer to participate in the Theft Excess Protection program does not create any contractual obligation or liability by DataDot Technology Ltd or DataDot Technology (UK) Ltd. to the vehicle purchaser or the insured beyond an obligation to refund the insured's insurance excess in accordance with the stated terms and conditions. The program does not create any obligation or liability on behalf of the participating vehicle manufacturer.</p>
                            <p />
                            <p>DataDot Technology Ltd<br />
                                8 Ethel Avenue Brookvale NSW Australia 2100<br />
                            Phone +61 2 8977 4900 Email: <a href="info@datadotdna.com">info@datadotdna.com</a><br />
                            </p>
                    </div>
                );
                break;
            case "privacy":
                content.title = "Privacy Policy";
                content.body = (
                    <div>
                        <p>AIM<br />
                            DataDot respects your privacy and is committed to protecting your personal information. We are committed to complying with the Privacy and Personal Information Protection Act 1988 (' PPIT Act') and the National Privacy Principles.<br />
                            This Privacy Policy outlines the general information handling practices you can expect from DataDot. If you require any further information concerning privacy and the ways in which we handle your personal information, please contact us via the contact details set out below.</p>
                        <p>
                            SCOPE<br />
                            This policy applies to:</p>
                        <p>DataDot Technology Limited;<br />
                            DataDot Technology (Australia) Pty Limited;<br />
                            DataTrace DNA Pty Limited;<br />
                            AgTechnix Pty Limited;<br />
                            And any other legal entities in the DataDot Technology Limited Group (DataDot), not specifically mentioned above, where applicable.</p>
                        <p>
                            A COLLECTION OF PERSONAL INFORMATION<br />
                            A.1 DataDot only collects personal information that is necessary for one or more of our functions or activities.</p>
                        <p>A.2 The types of personal information we collect and the purposes for collection will depend upon the particular circumstances. If you are a customer of DataDot, we will require details such as your name, contact details, the services that you require and such other personal information as may be necessary to enable us to provide our products and services to you. We collect personal data from visitors to the websites through the use of on-line forms, when you create an account to register your asset, if you wish to make a purchase and every time you email us your details or make an enquiry. You may visit the websites without registering or creating an account, however, if you choose not to create an account you may not be able to use the websites to their fullest extent. For example, you will be unable to register your asset.<br />
                            We are also sometimes passed your personal data by partner organisations, such as police and other public authorities, who have provided you with a SelectaDNA kit or BikeRegister kit and to whom you have provided your details. You may have received a shortened version of this privacy notice on a card or similar medium when you provided those details.<br />
                            We collect various types of personal data. For example, we will ask you for information such as your name, address and email address so that you can register with the websites to protect your asset. You may also choose to provide additional information such as details of the asset that you are protecting.<br />
                            If you wish to create an account in order to make purchases from the websites you must provide us with personal information, including credit/debit card information. We may also keep a record of the products you have purchased from the websites.</p>
                        <p>A.3 We also collect and use your personal information in order to keep you updated as to new products and services which we may offer you from time to time, to inform you about developments in our technology, to tailor our services to better suit your needs and for billing purposes.</p>
                        <p>A.4 The specific types of information we collect and reasons for collection may vary depending upon the particular circumstances.</p>
                        <p>A.5 If you provide DataDot with incorrect information, we may not be able to provide services to you.</p>
                        <p>A.6 If you are a visitor to our website, we will generally not collect personal information about you (for further information on our Internet Privacy practices, please see below).</p>
                        <p>A.7 DataDot will not collect sensitive information from its customers unless they specifically choose to provide us with that information. Sensitive information includes information relating to racial or ethnic origin, political opinions, religious beliefs, sexual preferences, health and philosophical beliefs.</p>
                        <p>
                            B USE AND DISCLOSURE OF PERSONAL INFORMATION<br />
                            B.1 DataDot's policy is to use your personal information only for the purpose or purposes for which it was collected.</p>
                        <p>B.2 DataDot will not rent, sell or trade your personal information to or with any third party without your consent.</p>
                        <p>B.3 DataDot may disclose personal information to third parties in the following circumstances:</p>
                        <p>(a) disclosures to external service providers who help us operate our business, provided that, where relevant or if required, DataDot will take reasonable steps to require those service providers to comply with the Act;<br />
                            (b) if you have consented to the disclosure; and<br />
                            (c) disclosures we are required or authorised to make by law or permitted to make under the Act.</p>
                        <p>B.4 In general, the types of organisations to which DataDot may disclose your personal information are those organizations that provide services that assist us in supplying to you, or in administering the products and services you require.</p>
                        <p>B.5 In addition, DataDot may out-source some of the services it provides to you and that process may involve disclosing personal information about you to other organisations. Such organisations may include DataDot's related entities, printing and mailing houses, accounting and legal advisors, collection agents, conveyancing and settlement agents and marketing consultants.</p>
                        <p>B.6 It may also be necessary for DataDot to disclose personal information to investors in or purchasers of equity in DataDot itself.</p>
                        <p>B.7 DataDot will take all reasonable steps to ensure that any organisations to which your personal information is provided will use the information only to the extent which is necessary to provide the services we require from them or strictly for the purpose for which the information is provided and will ensure that all such organisations are subject to confidentiality agreements.</p>
                        <p>B.8 DataDot co-operates with law enforcement inquires and other third parties to enforce legal, intellectual property and other rights. DataDot may be required to disclose information about you to law enforcement or other governmental officials as we, in our sole discretion, believe necessary, appropriate or as otherwise permitted under the Act in connection with an investigation of fraud, intellectual property infringements, piracy or any other activity that is illegal or may expose us to legal liability.</p>
                        <p>B.9 In some circumstances, DataDot may be required to give some personal information about you to other government organisations, such as the Australian Taxation Office, to comply with our legal obligations.</p>
                        <p>B.10 Where appropriate, DataDot will seek your consent before complying with any third party requests for personal information.</p>
                        <p>
                            B.11 We also collect personal information so that we may give you marketing information, unless you do not consent to that and advise our Privacy Officer accordingly (see below for contact details).</p>
                        <p>
                            C MARKETING INFORMATION<br />
                            C.1 You agree that we may use your personal information to give you information that may be of interest to you about all products and services that are available from us, from our related entities, and from other businesses with which we, or our related entities, have relationships.</p>
                        <p>C.2 You may, at any time, advise us that you do not wish to receive any direct marketing communication by sending an email to info@datadotid.com and advise us that you wish to opt out of any direct marketing communication.</p>
                        <p>
                            D CREDIT REFERENCES<br />
                            D.1 In the event that you wish to obtain any of our goods and services on credit (payment being made in excess of 7 days of delivery of any products or services), we are allowed, under the Act, to give a credit reporting agency information about your or your company's credit application. The information, which may be given to an agency, is referred to in Section 18 of the Act and could include</p>
                        <p>such matters as details of payments which become more than 60 days overdue or cheques drawn by you which have been dishonoured more than once.</p>
                        <p>D.2 In the event that you wish to obtain any of our goods and services on credit, we may obtain from a credit reporting agency a credit report containing personal information about your credit worthiness.</p>
                        <p>
                            E SECURITY OF INFORMATION<br />
                            E.1 DataDot takes reasonable steps to protect the personal information we hold about you from misuse, loss, unauthorised access, modification or disclosure.</p>
                        <p>E.2 Specifically our security measures include:<br />
                            - Firewalls to prevent hacking of our databases<br />
                            - Clauses in employee agreements requiring confidentiality<br />
                            - Appropriate security access to our office premises<br />
                            - The use of passwords to access database information<br />
                            - Document shredders for the disposal of written information</p>
                        <p>E.3 If we hold personal information about you and that information is no longer needed by us, we will take reasonable steps to destroy or permanently de-identify the relevant information.</p>
                        <p>E.4 Your personal information is kept on an electronic database which is accessible only by management and authorised personnel of DataDot. Any hard copies (such as printouts) of your personal information will be retained securely and will only be accessible to DataDot management and authorised personnel.</p>
                        <p>
                            F ACCESS AND CORRECTION<br />
                            F.1 Upon request, you may be provided with access to the personal information DataDot holds about you.</p>
                        <p>F.2 If you would like to make a request for access, please set out your request in writing and send it to our Privacy Compliance Officer at the contact details set out below. We reserve the right to charge any reasonable costs incurred by us in providing access.</p>
                        <p />
                        <p>
                            F.3 If you feel that any personal information DataDot holds about you is not accurate, complete and up to date, please contact our Privacy Compliance Officer at the contact details set out below. We will then take reasonable steps to ensure that your personal information is corrected.</p>
                        <p>
                            G INTERNET PRIVACY<br />
                            G.1 In general, you can visit our website without revealing personal information about yourself. However, some other types of information may be automatically collected, which do not personally identify you to DataDot.</p>
                        <p>G.2 When you visit our website, our internet service provider and website managers may make a record of your visit, logging information such as your email address and/or the domain name of your internet service provider (if applicable), the date and time of your visit, the pages accessed, the domain where you clicked through from, the type of browser you have used and the type of computer operating system you are using. This information is collected for trend and statistical purposes, and so that DataDot can enhance its website's functions and capabilities by understanding its customers better.</p>
                        <p>G.3 Our website makes use of cookie technology to provide you with a better and more efficient service next time you visit our website, and to help us tailor the website or advertisement to better match your interests and preferences. Cookies are unique identifiers which are sent from our website to your computer or system (PC), tracking the web pages you have visited. Cookies enable us to relate your use of the website to information that you have specifically provided to us (such as a password for any service we may provide via the website) and allow your web browser to track searches you have performed on our website. Cookies are stored on your PC, tablet or phone, not on our servers. You can generally elect to disable and erase cookies from your computer hard drive, or receive a warning before a cookie is stored. This may be done through your internet browser setting options.</p>
                        <p>G.4 DataDot's websites contain links to other websites and DataDot accepts no responsibility for the content of those sites or the privacy practices of the operators of those sites.</p>
                        <p>G.5 If you enter into an online transaction with DataDot, that transaction will be governed by the terms of this policy.</p>
                        <p>
                            H ONLINE ORDERING<br />
                            H.1 Our Online Ordering facility uses Secure Sockets Layer (SSL) protocol to safeguard your information during online transactions. This 128-bit SSL protocol allows for messages to be encrypted (scrambled) so that the information sent between your computer and ours is meaningless to anyone else. We only use 128-bit security. If a customer tries to access our Online Ordering facility with a browser that does not support 128-bit SSL, they are advised as such and cannot continue.</p>
                        <p>H.2 As part of the Online Ordering facility, when you log on, a session object is created to pass encrypted (secured) information from one screen to another. This session ID is not written to your hard disk, but stored in memory. Once your Online Ordering session is terminated, the session object is destroyed. However, to be able to utilize a session object, your browser must have cookies enabled.</p>
                        <p>
                            I CHILDREN UNDER 18 YEARS OF AGE<br />
                            I.1 DataDot does not intend to collect personal information from individuals under 18 years of age.</p>
                        <p>I.2 Unless otherwise disclosed during collection, DataDot will not provide any personally identifying information it knowingly receives from individuals under 18 to any third party for any purposes whatsoever.</p>
                        <p>
                            I.3 Where appropriate, DataDot will specifically instruct individuals under 18 not to submit such information on our websites or advertisements without the consent of a parent or guardian.</p>
                        <p>
                            J TRANSFERRING INFORMATION OUT OF AUSTRALIA<br />
                            J.1 In dealing with your personal information in any of the ways contemplated in this privacy policy, it may be necessary for DataDot to transfer that information out of Australia.</p>
                        <p>J.2 In the event that such a transfer of information is necessary, DataDot will deal with the information in the same way as it would as if it were dealing with the information in Australia.</p>
                        <p>
                            K DATADOT'S CONTACT DETAILS<br />
                            K.1 The Act gives you rights to access the personal information we hold about you and you can obtain this information by providing us with a written request. You can also ask us to correct any information which is inaccurate.</p>
                        <p>K.2 To contact the Company about privacy issues or consents please contact our Company Secretary as shown below:</p>
                        <p>The Company Secretary<br />
                            DataDot Technology Ltd<br />
                            8 Ethel Avenue<br />
                            Brookvale NSW 2100<br />
                            Telephone: (02) 8977 4900 Email: info@datadotid.com</p>
                        <p>
                            DISCLAIMER<br />
                            The information on this site, including source code, is the property of DataDot and is subject to copyright. Unauthorized use of the information is not permitted. DataDot accepts no responsibility for any loss, cost or expense arising from use of the information in this site. Full product and service information is available from the Headquarters of DataDot. All the information on this site is subject to change without notice.<br />
                            DataDot cannot guarantee the accuracy or completeness of the information.<br />
                            This site may contain information of, or links to, other parties. Their opinions are not necessarily those of DataDot. The presence of any link or information of other parties should not be construed as an endorsement of those parties or the accuracy of their information.<br />
                        </p>
                    </div>

                    
                    );
                break;
            case "cookie-policy":
                content.title = "Cookie Policy";
                content.body = ( 
                    <div id="cookie-declaration-insert">
                    </div>
                    );
                break;
            case "terms-and-conditions":
                content.title = "Terms and Conditions";
                content.body = (
                    <div>
                        <p>
                            Last updated August 29, 2022</p>

                        <p>TABLE OF CONTENTS</p>

                        <p>1. AGREEMENT TO TERMS<br />
                            2. INTELLECTUAL PROPERTY RIGHTS<br />
                            3. USER REPRESENTATIONS<br />
                            4. USER REGISTRATION<br />
                            5. PROHIBITED ACTIVITIES<br />
                            6. USER GENERATED CONTRIBUTIONS<br />
                            7. CONTRIBUTION LICENSE<br />
                            8. SUBMISSIONS<br />
                            9. SITE MANAGEMENT<br />
                            10. PRIVACY POLICY<br />
                            11. COPYRIGHT INFRINGEMENTS<br />
                            12. TERM AND TERMINATION<br />
                            13. MODIFICATIONS AND INTERRUPTIONS<br />
                            14. GOVERNING LAW<br />
                            15. DISPUTE RESOLUTION<br />
                            16. CORRECTIONS<br />
                            17. DISCLAIMER<br />
                            18. LIMITATIONS OF LIABILITY<br />
                            19. INDEMNIFICATION<br />
                            20. USER DATA<br />
                            21. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES<br />
                            22. CALIFORNIA USERS AND RESIDENTS<br />
                            23. MISCELLANEOUS<br />
                            24. CONTACT US</p>

                        <p>
                            1. AGREEMENT TO TERMS</p>

                        <p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (&ldquo;you&rdquo;) and DataDot Technology Limited (&quot;Company,&quot; &ldquo;we,&quot; &ldquo;us,&quot; or &ldquo;our&rdquo;), concerning your access to and use of the http://www.datadotglobal.com website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the &ldquo;Site&rdquo;). We are registered in Australia and have our registered office at 8 Ethel Avenue, Brookvale, New South Wales 2100. Our Company number is 54091908726. You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>

                        <p>Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use from time to time. We will alert you about any changes by updating the &ldquo;Last updated&rdquo; date of these Terms of Use, and you waive any right to receive specific notice of each such change. Please ensure that you check the applicable Terms every time you use our Site so that you understand which Terms apply. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the Site after the date such revised Terms of Use are posted.</p>

                        <p>The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>

                        <p>The Site is not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use this Site. You may not use the Site in a way that would violate the Gramm-Leach-Bliley Act (GLBA).</p>

                        <p>The Site is intended for users who are at least 18 years of age. All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the Site. If you are a minor, you must have your parent or guardian read and agree to these Terms of Use prior to you using the Site.</p>

                        <p>
                            2. INTELLECTUAL PROPERTY RIGHTS</p>

                        <p>Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the &ldquo;Content&rdquo;) and the trademarks, service marks, and logos contained therein (the &ldquo;Marks&rdquo;) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, international copyright laws, and international conventions. The Content and the Marks are provided on the Site &ldquo;AS IS&rdquo; for your information and personal use only. Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</p>

                        <p>Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.</p>

                        <p>
                            3. USER REPRESENTATIONS</p>

                        <p>By using the Site, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal capacity and you agree to comply with these Terms of Use; (4) you are not under the age of 13; (5) you are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Site; (6) you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise; (7) you will not use the Site for any illegal or unauthorized purpose; and (8) your use of the Site will not violate any applicable law or regulation.</p>

                        <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).</p>

                        <p>
                            4. USER REGISTRATION<br />
                            <br />
                            You may be required to register with the Site. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.</p>

                        <p>
                            5. PROHIBITED ACTIVITIES</p>

                        <p>You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.</p>

                        <p>As a user of the Site, you agree not to:<br />
                            Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.<br />
                            Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.<br />
                            Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein.<br />
                            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.<br />
                            Use any information obtained from the Site in order to harass, abuse, or harm another person.<br />
                            Make improper use of our support services or submit false reports of abuse or misconduct.<br />
                            Use the Site in a manner inconsistent with any applicable laws or regulations.<br />
                            Engage in unauthorized framing of or linking to the Site.<br />
                            Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party&rsquo;s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site.<br />
                            Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.<br />
                            Delete the copyright or other proprietary rights notice from any Content.<br />
                            Attempt to impersonate another user or person or use the username of another user.<br />
                            Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (&ldquo;gifs&rdquo;), 1&times;1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as &ldquo;spyware&rdquo; or &ldquo;passive collection mechanisms&rdquo; or &ldquo;pcms&rdquo;).<br />
                            Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site.<br />
                            Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you.<br />
                            Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.<br />
                            Copy or adapt the Site&rsquo;s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.<br />
                            Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site.<br />
                            Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software.<br />
                            Use a buying agent or purchasing agent to make purchases on the Site.<br />
                            Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.<br />
                            Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavor or commercial enterprise.</p>

                        <p>
                            6. USER GENERATED CONTRIBUTIONS</p>

                        <p>The Site may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, &quot;Contributions&quot;). Contributions may be viewable by other users of the Site and through third-party websites. As such, any Contributions you transmit may be treated as non-confidential and non-proprietary. When you create or make available any Contributions, you thereby represent and warrant that:<br />
                            The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.<br />
                            You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Site, and other users of the Site to use your Contributions in any manner contemplated by the Site and these Terms of Use.<br />
                            You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Site and these Terms of Use.<br />
                            Your Contributions are not false, inaccurate, or misleading.<br />
                            Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.<br />
                            Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).<br />
                            Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.<br />
                            Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.<br />
                            Your Contributions do not violate any applicable law, regulation, or rule.<br />
                            Your Contributions do not violate the privacy or publicity rights of any third party.<br />
                            Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.<br />
                            Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.<br />
                            Your Contributions do not otherwise violate, or link to material that violates, any provision of these Terms of Use, or any applicable law or regulation.<br />
                            Any use of the Site in violation of the foregoing violates these Terms of Use and may result in, among other things, termination or suspension of your rights to use the Site.</p>

                        <p>
                            7. CONTRIBUTION LICENSE</p>

                        <p>By posting your Contributions to any part of the Site, you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, or incorporate into other works, such Contributions, and grant and authorize sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels.</p>

                        <p>This license will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in your Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions.</p>

                        <p>We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the Site. You are solely responsible for your Contributions to the Site and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.</p>

                        <p>We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to re-categorize any Contributions to place them in more appropriate locations on the Site; and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor your Contributions.</p>

                        <p>
                            8. SUBMISSIONS</p>

                        <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site (&quot;Submissions&quot;) provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you. You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.</p>

                        <p>
                            9. SITE MANAGEMENT</p>

                        <p>We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.</p>

                        <p>
                            10. PRIVACY POLICY</p>

                        <p>We care about data privacy and security. Please review our Privacy Policy: __________. By using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Use. Please be advised the Site is hosted in Australia. If you access the Site from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in Australia, then through your continued use of the Site, you are transferring your data to Australia, and you agree to have your data transferred to and processed in Australia. Further, we do not knowingly accept, request, or solicit information from children or knowingly market to children. Therefore, in accordance with the U.S. Children&rsquo;s Online Privacy Protection Act, if we receive actual knowledge that anyone under the age of 13 has provided personal information to us without the requisite and verifiable parental consent, we will delete that information from the Site as quickly as is reasonably practical.</p>

                        <p>
                            11. COPYRIGHT INFRINGEMENTS</p>

                        <p>We respect the intellectual property rights of others. If you believe that any material available on or through the Site infringes upon any copyright you own or control, please immediately notify us using the contact information provided below (a &ldquo;Notification&rdquo;). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification. Please be advised that pursuant to applicable law you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the Site infringes your copyright, you should consider first contacting an attorney.</p>

                        <p>
                            12. TERM AND TERMINATION</p>

                        <p>These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</p>

                        <p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>

                        <p>
                            13. MODIFICATIONS AND INTERRUPTIONS</p>

                        <p>We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.</p>

                        <p>We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.</p>

                        <p>
                            14. GOVERNING LAW</p>

                        <p>These Terms shall be governed by and defined following the laws of Australia. DataDot Technology Limited and yourself irrevocably consent that the courts of Australia shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.</p>

                        <p>
                            15. DISPUTE RESOLUTION</p>

                        <p>Informal Negotiations</p>

                        <p>To expedite resolution and control the cost of any dispute, controversy, or claim related to these Terms of Use (each &quot;Dispute&quot; and collectively, the &ldquo;Disputes&rdquo;) brought by either you or us (individually, a &ldquo;Party&rdquo; and collectively, the &ldquo;Parties&rdquo;), the Parties agree to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.</p>

                        <p>Binding Arbitration</p>

                        <p>Any dispute arising out of or in connection with this contract, including any question regarding its existence, validity, or termination, shall be referred to and finally resolved by the International Commercial Arbitration Court under the European Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according to the Rules of this ICAC, which, as a result of referring to it, is considered as the part of this clause. The number of arbitrators shall be __________. The seat, or legal place, of arbitration shall be Sydney, Australia. The language of the proceedings shall be English. The governing law of the contract shall be the substantive law of Australia.</p>

                        <p>Restrictions</p>

                        <p>The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.</p>

                        <p>Exceptions to Informal Negotiations and Arbitration</p>

                        <p>The Parties agree that the following Disputes are not subject to the above provisions concerning informal negotiations and binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.</p>

                        <p>
                            16. CORRECTIONS</p>

                        <p>There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.</p>

                        <p>
                            17. DISCLAIMER</p>

                        <p>THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE&rsquo;S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>

                        <p>
                            18. LIMITATIONS OF LIABILITY</p>

                        <p>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>

                        <p>
                            19. INDEMNIFICATION</p>

                        <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys&rsquo; fees and expenses, made by any third party due to or arising out of: (1) your Contributions; (2) use of the Site; (3) breach of these Terms of Use; (4) any breach of your representations and warranties set forth in these Terms of Use; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the Site with whom you connected via the Site. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>

                        <p>
                            20. USER DATA</p>

                        <p>We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as well as data relating to your use of the Site. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Site. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.</p>

                        <p>
                            21. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</p>

                        <p>Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.</p>

                        <p>
                            22. CALIFORNIA USERS AND RESIDENTS</p>

                        <p>If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.</p>

                        <p>
                            23. MISCELLANEOUS</p>

                        <p>These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.</p>

                        <p>
                            24. CONTACT US</p>

                        <p>In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at:</p>

                        <p>DataDot Technology Limited<br />
                            8 Ethel Avenue<br />
                            Brookvale, New South Wales 2100<br />
                            Australia<br />
                            Phone: +614 89 774900<br />
                            info@datadotid.com</p>
                    </div>
                );
                break;
        }


        return(
            <React.Fragment>
                {/* ======= Breadcrumbs Section ======= */}
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>{content.title}</h2>
                            <ol>
                                <li><a href="/">Home</a></li>
                                <li>{content.title}</li>
                            </ol>
                        </div>
                    </div>
                </section>{/* End Breadcrumbs Section */}
                <section className="inner-page">
                    <div className="container">
                        {content.body}
                    </div>
                </section>
            </React.Fragment >
        );
    }
};

export default (Content as any);