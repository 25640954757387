import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import ReCAPTCHA from "react-google-recaptcha";
import { closeMobileNav } from '../store/common';

// At runtime, Redux will merge together...
type LoginProps = LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

interface FormValidation {
    formSubmitAttempted: boolean,
    formIsValid: boolean,
    errors: Map<string,string>
}

interface LoginViewState {
    login: LoginStore.LoginModel,
    captcha: string,
    validation: FormValidation
}

class Login extends React.PureComponent<LoginProps, LoginViewState> {

    state: LoginViewState = {
        login: { password: '', email: ''},
        validation: { formIsValid: false, formSubmitAttempted: false, errors: new Map<string, string>() },
        captcha: ""
    }

    componentWillMount() {
        closeMobileNav();
    }

    runValidation = (state: LoginViewState, login: LoginStore.LoginModel): FormValidation => {
        let validation: FormValidation = { formIsValid: state.validation.formIsValid, formSubmitAttempted: state.validation.formSubmitAttempted, errors: new Map<string, string>() }

        const redirect = this.props!.loginResult ? this.props!.loginResult!.redirect : undefined;

        if (redirect == undefined) {
            if (login.password == "") {
                validation.errors.set("password", "You must provide a password");
            }
            if (login.email == "") {
                validation.errors.set("email", "You must provide an email address");
            }
        }
        else {
            if (login.code == "") {
                validation.errors.set("code", "You must provide a code");
            }
        }

        validation.formIsValid = validation.errors.size === 0;
        return validation;
    }

    onChange = (id:string,value:string | null) => {
        // const newValue = e.target.value;
        // const id = e.target.id;
        // console.log("e.target",e.target);
        var newValue = value || "";

        this.setState((prevState) => {

            let login: LoginStore.LoginModel = {
                password: id == "password" ? newValue : prevState.login.password,
                email: id == "email" ? newValue : prevState.login.email,
                code: id == "code" ? newValue : prevState.login.code,
            }

            let validation = this.runValidation(prevState, login);

            return { login, validation, captcha: prevState.captcha };
        });
    }

    onChangeSelect = (event: any) => {
        const value = event.value;
        console.log(value);
        this.onChange("countryCode", value);
        //setSelectedOption(value);
         //"countryCode", e === null || e === undefined ? '' : e.value as CountryData)
    };

    submitForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log("test");
        let validation = this.runValidation(this.state, this.state.login);
        
        this.setState((prevState) => {
            validation.formSubmitAttempted = true;
            return {validation}
        });

        if (validation.formIsValid) {
            console.log("would've fired");
            const redirect = this.props!.loginResult ? this.props!.loginResult!.redirect : undefined;
            if (redirect === undefined)
                this.props.sendLogin(this.state.login, this.state.captcha);
            else
                this.props.sendCode(this.state.login, redirect);
        }
        
    }

    public onCapChange(value: any) {
        console.log("Captcha value:", value);
        this.setState((prevState) => {
            return { captcha: value };
        });
    }

    public render() {

        var validation = (field: string) => {
            if (!this.state.validation.formSubmitAttempted)
                return null;
            var err = this.state.validation.errors.get(field);
            if(err != undefined)
                return (<div className="validate">{err}</div>)
            if (this.props.loginResult !== undefined && this.props.loginResult.modelState != undefined && this.props.loginResult.modelState[field as keyof LoginStore.ModelState] != undefined) {
                return (<div className="validate">{this.props.loginResult.modelState[field as keyof LoginStore.ModelState]}</div>)
            }
            return null
        }

        const redirect = this.props!.loginResult ? this.props!.loginResult!.redirect : undefined;

        // console.log(this.props.loginResult);
        if (redirect == "manager") {
            return (<Redirect
                to="/manager"
            />);
        }

        return (
            <React.Fragment>
                {/* ======= Hero Section ======= */}
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                    </div>
                </section>{/* End Hero */}

                <main id="main">

                    {/* ======= Why Us Section ======= */}
                    <section id="why-us" className="why-us">
                        <div className="container appointment" id="appointment">
                            <div className="row">
                                <div className="col-lg-4 offset-lg-4 d-flex align-items-stretch">
                                    <div className="content">
                                        {redirect === "twofactor" ? (
                                            <form className="php-email-form">
                                                <h3>2Factor Authenticator Code</h3>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <input type="text" name="code" id="code" className="form-control" placeholder="Code" autoComplete="off" onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                                        {validation("code")}
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    {this.props.isLoading &&
                                                        <div className="loading">Loading</div>
                                                    }
                                                </div>
                                                <div className="text-center"><button type="button" onClick={(e) => { this.submitForm(e) }}>Send</button></div>
                                            </form>
                                        ) :
                                        redirect === "twofactorinstall" ? (
                                            <form className="php-email-form">
                                                <h3>Add to your Authenticator App</h3>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <img src={this.props!.loginResult!.imageUrl} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <input type="text" name="code" id="code" className="form-control" placeholder="Code" autoComplete="off" onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                                        {validation("code")}
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    {this.props.isLoading &&
                                                        <div className="loading">Loading</div>
                                                    }
                                                </div>
                                                <div className="text-center"><button type="button" onClick={(e) => { this.submitForm(e) }}>Send Code</button></div>
                                            </form>
                                         ) : (
                                                    
                                            <form className="php-email-form">
                                                <h3>Login</h3>
                                                <div className="row">
                                                    <div className="col-md-6 form-group">
                                                        <input type="text" name="email" id="email" className="form-control" placeholder="Email" onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                                        {validation("email")}
                                                    </div>
                                                    <div className="col-md-6 form-group">
                                                        <input type="password" name="password" id="password" className="form-control" placeholder="Password" onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                                        {validation("password")}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="row">
                                                        <ReCAPTCHA
                                                            sitekey="6LcOyUMhAAAAAKhVvP3bZ1hKmumd7v2gxr1kDTgo"
                                                            onChange={(val) => this.onCapChange(val)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    {this.props.isLoading &&
                                                        <div className="loading">Loading</div>
                                                    }
                                                </div>
                                                <div className="text-center"><button type="button" onClick={(e) => { this.submitForm(e) }}>Login</button></div>
                                            </form>
                                        )
                                       }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>{/* End Why Us Section */}

                </main>
            </React.Fragment>
        );
    }

   
}

export default connect(
    (state: ApplicationState) => state.login, // Selects which state properties are merged into the component's props
    LoginStore.actionCreators
)(Login as any);
