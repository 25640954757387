import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface BatchesState {
    isLoading: boolean;
    batch: Batch | null;
    modelState: any | null;
}

export interface Batch {
    id: number;
    name: string;
    createDate: string | null;
    identCodes: string | undefined;
    fileData: any;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestBatchUploadAction {
    type: 'REQUEST_BATCH_UPLOAD';
    batch: Batch;
}

interface ReceiveBatchUploadAction {
    type: 'RECEIVE_BATCH_UPLOAD';
    batch: Batch;
}

interface ReceiveBatchValidationAction {
    type: 'RECEIVE_BATCH_VALIDATION';
    modelState: Batch;
}

interface DoneBatchValidationAction {
    type: 'BATCH_DONE';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestBatchUploadAction | ReceiveBatchUploadAction | ReceiveBatchValidationAction | DoneBatchValidationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    uploadBatchDone: () => ({ type: 'BATCH_DONE' } as DoneBatchValidationAction),
    uploadIdentBatch : (batch: Batch): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.batches) {
            const formData = new FormData();

            formData.append('File', batch.fileData);
            formData.append('name', batch.name);

            fetch('/api/batches/identbatches',
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then(response => {
                    if (!response.ok) {
                        return (response.json() as Promise<any>);
                    }
                    return response.json() as Promise<Batch>;
                })
                .then(data => {
                    if (data.id)
                        dispatch({ type: 'RECEIVE_BATCH_UPLOAD', batch: data as Batch });
                    else
                        dispatch({ type: 'RECEIVE_BATCH_VALIDATION', modelState: data });
                })
                .catch(error => {
                    console.error('There has been a problem with your fetch operation:', error);
                });

            dispatch({ type: 'REQUEST_BATCH_UPLOAD', batch });
        }
    },
    uploadVinBatch: (batch: Batch): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.batches) {
            const formData = new FormData();

            formData.append('File', batch.fileData);
            formData.append('name', batch.name);

            fetch('/api/batches/vinbatches',
                    {
                        method: 'POST',
                        body: formData,
                    }
                )
                .then(response => {
                    if (!response.ok) {
                        return (response.json() as Promise<any>);
                    }
                    return response.json() as Promise<Batch>;
                })
                .then(data => {
                    if (data.id)
                        dispatch({ type: 'RECEIVE_BATCH_UPLOAD', batch: data as Batch });
                    else
                        dispatch({ type: 'RECEIVE_BATCH_VALIDATION', modelState: data });
                })
                .catch(error => {
                    console.error('There has been a problem with your fetch operation:', error);
                });

            dispatch({ type: 'REQUEST_BATCH_UPLOAD', batch });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: BatchesState = { batch: null, isLoading: false, modelState: null };

export const reducer: Reducer<BatchesState> = (state: BatchesState | undefined, incomingAction: Action): BatchesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_BATCH_UPLOAD':
            // let test = "sdfas";
            return {
                batch: state.batch,
                isLoading: true,
                modelState: null
            };
        case 'RECEIVE_BATCH_UPLOAD':
            return {
                batch: action.batch,
                isLoading: false,
                modelState: null
            };
        case 'RECEIVE_BATCH_VALIDATION':
            return {
                batch: state.batch,
                isLoading: false,
                modelState: action.modelState
            };
        case 'BATCH_DONE':
            return {
                batch: null,
                isLoading: false,
                modelState: null
            };
    }

    return state;
};
